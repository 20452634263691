.time-select {
  width: 100%;
  color: inherit;

  .mat-mdc-form-field-text-suffix:has(.next-day-suffix) {
    top: 0;
  }

  // Hack for Chrome material issue - automatically adds ::after content to time input
  .time-input[type='time'] {
    &::after {
      content: '';
    }
  }
}
